<template lang="">
    <div class="d-flex flex-column position-fixed top-0 start-0 vh-100 w-100 bg-canvas">
        <HeaderMain></HeaderMain>

        <!-- subnavigation -->
        <div class="container-fluid border-bottom bg-white shadow-sm position-relative py-2 px-4 d-flex align-items-center">
            <span>
                <ButtonCircle class="btn-primary" icon="navigate_before" :onClick="$router.back"></ButtonCircle>

                <nav class="d-inline-block ms-3" style="--bs-breadcrumb-divider: '›';">
                    <ol class="breadcrumb mb-0 fw-500 font-size-18 lh-lg">
                        <li class="breadcrumb-item">Forms</li>
                    </ol>
                </nav>
            </span>

            <span class="flex-fill"></span>

            <span>
                <ButtonCircle class="" icon="file_upload"></ButtonCircle>
                <ButtonCircle class="ms-2" icon="file_download"></ButtonCircle>
                <ButtonMain class="ms-2 rounded-pill btn-sm fs-6" icon="add" :onClick="createForm">New Form</ButtonMain>
            </span>
        </div>

        <div class="container-fluid overflow-scroll flex-fill w-100 py-5">
            <div class="pb-5">
                <div class="mx-auto" :style="{width: variableContainerWidth + 'px'}">
                    <div class="" v-for="(item, index) in collection" :key="index" >
                        <WorkbookCard class="mb-4 float-start mx-3"
                            :data="item"
                            :options="options"
                            :onClick="openItem"
                            :onOption="onOption"
                            :width="current.content.cardWidth"
                            ></WorkbookCard> 
                    </div>

                    <div v-if="collection?.length === 0 && mounted" class="col-12 text-center">
                        <h1>Create your first Workbook</h1>
                        <a class="btn btn-outline-primary py-0 me-2" href="#" @click.prevent="createWorkbook()">Create</a>
                    </div>
                </div>
            </div>

        </div>
        <FooterMain class=""></FooterMain>
    </div>
</template>


<script>

import WorkbookCard from "./widgets/WorkbookCard.vue";

export default {
    components: {
        WorkbookCard
    },

    data() {
        return {
            collection: [],
            mounted: false, 
            current: {
                content: {
                    width: document.body.offsetWidth,
                    cardWidth: 280
                }
            }
        }
    },

    computed: {
        // workbooks() {
        //     return this.collection;
        // },

		options() {
			return [
                this.$ui.helper.options.EDIT,
                this.$ui.helper.options.DETAILS,
                this.$ui.helper.options.DOWNLOAD,
                this.$ui.helper.options.DIVIDER,
                this.$ui.helper.options.DELETE,
            ]
		}, 

        variableContainerWidth() {
            let cardWidth = this.current.content.cardWidth + 32;
            let width = Math.floor(this.current.content.width / cardWidth) * cardWidth;
            return width
        }
    },

    methods: {


        openItem(data) {
            let name = data.name;
			let path = this.$root.links.FORMS + '/' + name;

            // alert(path);
			this.$root.$router.push( {path: path} );
        },


        /**
         * Edit Strategy details
         * 
         * @param {Object} callback
         * 
         */
        createForm(callback) {
            // let root = this.$root;
            let modal = this.$root.modal({
                title: 'Create Form',
                okay: {
                    label: 'CREATE',
                    visible: true,
                    disabled: false,
                    callback: async (component) => {

                        let request = {
                            // ID: 0,
                            post_title: component.data.title,
                            post_name: component.data.title,
                            post_excerpt: component.data.excerpt, 
							meta_input: {
								/**
								 * Create metadata to link Wordkbook and Form (one-to-many)
								 */
								// workbook: component.data.workbook,
								group: component.data.group,
								type: component.data?.type?.toLowerCase() || 'generic'
							}
                        };


                        console.log('createForm', request);

						let response = await this.$store.dispatch('createForm', request);
						
                        // await this.updateFormsOrder(response.ID);

                        await this.refresh(true);

						// this.setCurrentForm(response);

						typeof callback == 'function' && callback.apply(null, [response]);

						this.$root.toast(`The form "${component.data.title}" has been created.`);
						
                    }
                }, 

            }, 'ModalContentForm');

            setTimeout(() => {
				/**
				 * Setup opportunity data as copy to prevent Vue reactivity from
				 * editing data in $store.state as user types-in in form
				 */
				let content = modal.content();
                content.data.title = '';
                content.data.excerpt = '';
                // content.data.workbook = this.current.workbook.ID;
                // content.data.group = this.current.workbook.meta?.group || '';
				content.validate = function() {
					let isValid = false;
					if('title' in this.data && this.data.title?.length > 0) {
						isValid = true;
					}
					if(!isValid) {
						alert('Please fill out all required fields');
					}
					return isValid;
				};
            }, 0);

			return modal;
        },


        deleteFormIntent(item) {
            this.$root.notify({
                title: 'Confirm',
                type: 'confirm',
                message: 'Would you want to delete this form (' + item.title + ')?',
                callback: (okay)=> {
                    okay && this.deleteForm(item);
                }
            });
        },


        async deleteForm(item) {
            let response = await this.$store.dispatch('deleteForm', item);
            await this.refresh(true);
            this.$root.toast(`The form "${item.title}" has been deleted.`);
            response
        }, 



        /**
         * Download, delete, or show details of a workbook based on
         * a selection from a dropdown menu.
         * 
         * @param option
         * @param option.name
         * @param option.title
         * @param option.type
         * @param option.icon
         * @param option.disabled
         * @param option.classes
         * 
         * @param workbook
         * @param workbook.ID
         * @param workbook.name
         * @param workbook.title
         * @param workbook.excerpt
         * 
         */
        onOption(option, workbook) {
            let name = option?.name;

            switch (name) {
                case 'edit':
                    // this.editWorkbook(workbook);
                    alert('TODO')
                    break;


                case 'details':
                    // this.editWorkbookDetailsIntent(workbook);
                    alert('TODO')
                    break;
                    
                case 'download':
                    // this.downloadWorkbookIntent(workbook);
                    alert('TODO')
                    break;
                    
                case 'delete':
                    this.deleteFormIntent(workbook);
                    break;
            
                default:
                    break;
            }
        },


        async refresh(forceReload=false) {
            // this.collection = await this.$store.dispatch('getWorkbooks', forceReload);
            this.collection = await this.$store.dispatch('getForms', forceReload);
        }
    }, 


    async mounted() {
        (this.collection.length === 0) && (await this.refresh(false));
        this.mounted = true;

        window.addEventListener('resize', () => {
            this.current.content.width = document.body.offsetWidth;
        }, {one: true});
    }
};
</script>

<style scoped>
.workbook-options {
    opacity: 0;
    transition: all 0.3s;
    transition-delay: 0.1s;
}
.card:hover .workbook-options {
    opacity: 1;
}

.card .card-body small {
    min-height: 42px;
}

.card .initials {
    color: rgba(0,0,0,.2);
    /* text-shadow: inset 0 2px 1px rgba(0,0,0,.5); */
}

.card .btn.bg-light:hover {
    background-color: #e9ecef !important;
    /* border: solid 1px #adb5bd; */
}
</style>