<template>
  <div class="card card-workbook border-0 shadow-sm d-flex flex-column" :style="{
      width: width +'px',
      height: height +'px',
    }">
        <div>
            <div v-if="showIcon" class="ratio ratio-16x9 card-img-top position-relative">
                <p class="fs-1 d-block w-100 h-100 d-flex justify-content-center    align-items-center">
                    <small class="initials display-5 fw-lighter rounded-circle text-center text-muted pt-3 hover:cursor-pointer" 
                        @click.prevent="click(data)" 
                        :style="{
                            'background-color': (data?.meta?.color || '#fefefe'),
                            width: '86px',
                            height: '86px',
                            'box-shadow': 'inset 0 10px 10px 10px rgba(100,100,100,.05)'
                        }">
                        {{data?.title.toUpperCase().substr(0, 2)}}
                    </small>
                </p>
            </div>
            <OptionsDropdown v-if="options" class="workbook-options position-absolute top-0 end-0 m-2" 
                :context="data"
                :onClick="onOption" 
                :collection="options"></OptionsDropdown>
        </div>

        
        <div class="card-body flex-fill d-flex flex-column text-center">
            <h5 class="card-title mb-1">
                <a href="#" @click.prevent="click(data)" class="text-reset text-decoration-none">
                    {{ data?.title }}
                </a>
            </h5>
            <small class="d-block text-muted flex-fill">{{ data?.excerpt }}</small>
            
            <!-- <div class="text-center pt-4 d-flex">
                <a href="#" @click.prevent="click($root.links.WORKBOOKS + '/' + data.name)" class="btn btn-sm bg-light flex-fill me-3">Edit Workbook</a>
                <a href="#" @click.prevent="click($root.links.RUN + '/' + data.name)" class="btn btn-sm btn-primary flex-fill">Launch</a>
            </div> -->
        </div>
    </div>
</template>

<script>
export default {

    props: {
        data: {
            type: Object
        }, 

        showIcon: {
            type: Boolean,
            default: () =>{
                return true
            }
        },
        options: {
            type: Object,
            default: () =>{
                return null
            }
        },
        width: {
            type: Number,
            default: ()=> {
                return 300
            }
        },
        height: {
            type: Number,
            default: ()=> {
                return 270
            }
        },
        onClick: Function,
        onOption: Function
    },


    methods: {
        option(data) {
            if(this.onOption) {
                this.onOption(data)
            }
        }, 

        click(data) {
            if(this.onClick) {
                this.onClick(data)
            }
        }
    }

}
</script>

<style>

.card-workbook  .hover\:cursor-pointer {
    transition: all 0.25s;
    box-shadow: 0 0 0px 2px transparent !important;
}
.card-workbook  .hover\:cursor-pointer:hover {
    cursor: pointer;
    box-shadow: 0 0 0px 2px var(--primary) !important;
}

.card-workbook .ui-btn-circle {
    background-color: transparent;
    color: rgb(120, 120, 120);
    transition: all 0.25s;
}
.card-workbook .ui-btn-circle:hover {
    background-color: #eee;
    color: #222;
}

.card-workbook .card-title a:hover {
    color: var(--primary) !important;
}

.card-workbook .workbook-options .dropdown-menu {
    transform: translate(-134px, 38px) !important;
}

</style>